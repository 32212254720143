import { GET_FILES_BY_ORDER_ID, RESET_FILES_BY_ORDER_ID } from '../actions/types';

const initialState = () => {
  return {
    list: []
  };
};
export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_FILES_BY_ORDER_ID: {
      return { ...state, ...{ list: action.filesData } };
    }
    case RESET_FILES_BY_ORDER_ID: {
      return { ...initialState() };
    }
    default:
      return state;
  }
}
