import { GET_NOTIFICATION_LIST, ADD_NOTIFICATION, DELETE_NOTIFICATION, ADD_INVOKE_FUNCTION } from './types';

export default class NotificationsAction {
  static receiveNotificationList(data) {
    return {
      type: GET_NOTIFICATION_LIST,
      notificationList: data
    };
  }

  static getNotificationList(service) {
    return function(dispatch) {
      service().then(data => {
        dispatch(NotificationsAction.receiveNotificationList(data));
      });
    };
  }

  static addNotificationItem(data) {
    return {
      type: ADD_NOTIFICATION,
      item: data
    };
  }

  static removeNotificationItem(id) {
    return {
      type: DELETE_NOTIFICATION,
      id
    };
  }

  static addInvokeFunction(func) {
    return {
      type: ADD_INVOKE_FUNCTION,
      func
    };
  }
}
