import React from 'react';
import MainLayout from '../partials/MainLayout';
import LoginLayout from '../partials/LoginLayout';

export function withMainLayout(Component) {
  return function(props) {
    return (
      <MainLayout>
        <Component {...props} />
      </MainLayout>
    );
  };
}

export function withLoginLayout(Component) {
  return function(props) {
    return (
      <LoginLayout>
        <Component {...props} />
      </LoginLayout>
    );
  };
}
