// import parseISO from 'date-fns/parseISO';
import { GET_TASK_FROM_MEMORY, POST_TASK_INTO_MEMORY, RESET_TASK_FROM_MEMORY } from '../actions/types';

const initialState = () => {
  return {
    value: {}
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_TASK_FROM_MEMORY: {
      return { ...state };
    }
    case POST_TASK_INTO_MEMORY: {
      return { ...state, ...{ value: action.data } };
    }
    case RESET_TASK_FROM_MEMORY: {
      return { ...initialState() };
    }
    default:
      return state;
  }
}
