import { ServicesConfig } from './config';
import { DataService } from './dataService';

export default class CurrentUserService {
  static async getCurrentUserData(userId) {
    const result = await DataService.sendRequest(`${ServicesConfig.currentUserEndpoint}/${userId}`, 'GET');
    return result;
  }

  static async getCurrentUser(userId) {
    const result = await CurrentUserService.getCurrentUserData(userId);
    return result.error ? result.error : result.data;
  }
}
