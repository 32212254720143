import { GET_CLIENT_CULTURES } from '../actions/types';

const initialState = {
  list: []
};
// const initialState = count => {
//   const tableData = [];
//   for (let i = 1; i <= count; i++) {
//     const obj = {
//       name: `test${i}`,
//       total_area: i * 10,
//       under_ours: i * 5,
//       ammount: i * 8,
//       input_norm: i * 2,
//       multiplicity: i * 12,
//       all_in_all: i,
//       microfertilizers: i % 2 === 0 ? 'Tecamin Brix (1L)' : 'FERTIGRANT START COMO',
//       fertilizer_cost: i * 100,
//       result: i * 1000
//     };
//     tableData.push(obj);
//   }
//   return { tableData };
// };

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CLIENT_CULTURES: {
      return {
        ...state,
        ...{ list: action.clientCulturesData },
        ...{ totalAmount: action.clientCulturesData.totalAmount }
      };
    }
    default:
      return state;
  }
}
