import { GET_ORDER_TYPES, RESET_ORDER_TYPES } from '../actions/types';

const initialState = () => {
  return {
    list: []
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_ORDER_TYPES: {
      return { ...state, ...{ list: action.orderTypesData } };
    }
    case RESET_ORDER_TYPES: {
      return { ...initialState() };
    }
    default:
      return state;
  }
}
