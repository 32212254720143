import { GET_YEARS_LIST, GET_YEARS_HARVEST_LIST } from '../actions/types';

const initialState = {
  list: [],
  harvestList: []
};
// const initialState = [
//   { id: 1, name: '2017' },
//   { id: 2, name: '2018' },
//   { id: 3, name: '2019' },
//   { id: 4, name: '2020' }
// ];

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_YEARS_LIST: {
      return { ...state, ...{ list: action.yearsData } };
    }
    case GET_YEARS_HARVEST_LIST: {
      return { ...state, ...{ harvestList: action.yearsData } };
    }
    default:
      return state;
  }
}
