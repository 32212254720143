import React, { Suspense, useCallback, useEffect } from 'react';
import { HubConnectionBuilder, HttpTransportType, LogLevel } from '@aspnet/signalr';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './utils/theme';
import './App.css';
import Loading from './components/Loading/Loading';
import CurrentUserActions from './store/actions/currentUser';
import { withMainLayout, withLoginLayout } from './containers/layout/utils';
import NotFound from './pages/NotFound/NotFound';
import PrivateRoute from './components/common/PrivateRoute';
import NotificationsAction from './store/actions/notification';
import NotificationsService from './store/services/notification';
import { toastr } from 'react-redux-toastr';

const WaitingComponent = Component => {
  return props => (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  );
};

const Home = withMainLayout(React.lazy(() => import('./pages/Home/HomeContainer')));
const Login = withLoginLayout(React.lazy(() => import('./pages/Login/LoginContainer')));
const EmailConfirmation = withLoginLayout(React.lazy(() => import('./pages/Login/components/EmailConfirmed')));
const ResetPassword = withLoginLayout(React.lazy(() => import('./pages/Login/ResetPasswordContainer')));
const Poc = React.lazy(() => import('./pages/Poc/PocContainer'));
const Config = withMainLayout(React.lazy(() => import('./pages/Config/ConfigContainer')));
const Dashboard = withMainLayout(React.lazy(() => import('./pages/Dashboard/DashboardContainer')));
const Tasks = withMainLayout(React.lazy(() => import('./pages/Tasks/TasksContainer')));
const Costs = withMainLayout(React.lazy(() => import('./pages/Costs/CostsContainer')));
const NewEditTask = withMainLayout(React.lazy(() => import('./pages/Tasks/new-edit-task/NewEditTask')));
const NewEditTaskHistory = withMainLayout(React.lazy(() => import('./pages/Tasks/new-edit-task/NewEditTaskHistory')));
const Calendar = withMainLayout(React.lazy(() => import('./pages/Calendar/CalendarContainer')));
const NewEditCalendar = withMainLayout(React.lazy(() => import('./pages/Calendar/new-edit-calendar/NewEditCalendar')));
const Clients = withMainLayout(React.lazy(() => import('./pages/Clients/ClientsContainer')));
const NewEditClient = withMainLayout(React.lazy(() => import('./pages/Clients/new-edit-client/NewEditClient')));
const Orders = withMainLayout(React.lazy(() => import('./pages/Orders/OrdersContainer')));
const NewEditOrder = withMainLayout(React.lazy(() => import('./pages/Orders/new-edit-order/NewEditOrder')));
const Accounts = withMainLayout(React.lazy(() => import('./pages/Accounts/AccountsContainer')));
const Warehouse = withMainLayout(React.lazy(() => import('./pages/Warehouse/WarehouseContainer')));
const Bonuses = withMainLayout(React.lazy(() => import('./pages/Bonuses/BonusesContainer')));
const Users = withMainLayout(React.lazy(() => import('./pages/Users/UsersContainer')));
const NewEditUser = withMainLayout(React.lazy(() => import('./pages/Users/new-edit-user/NewEditUser')));
const Products = withMainLayout(React.lazy(() => import('./pages/Products/ProductsContainer')));
const WarehouseOrder = withMainLayout(React.lazy(() => import('./pages/WarehouseOrder/OrdersContainer')));
const Reports = withMainLayout(React.lazy(() => import('./pages/Reports/ReportsContainer')));
const NewEditWarehouseOrder = withMainLayout(
  React.lazy(() => import('./pages/WarehouseOrder/new-edit-order/NewEditOrder'))
);
const NewEditCosts = withMainLayout(React.lazy(() => import('./pages/Costs/new-edit-cost/NewEditCosts')));
const TrackingsContainer = withMainLayout(React.lazy(() => import('./pages/Trackings/TrackingsContainer')));
const SubTrackingsContainer = withMainLayout(React.lazy(() => import('./pages/Trackings/SubTrackingsContainer')));

function App() {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector(state => state.auth);
  const onGetCurrentUser = useCallback(() => dispatch(CurrentUserActions.getCurrentUser(user.userId)), [
    dispatch,
    user
  ]);

  const postMessage = useCallback(data => dispatch(NotificationsAction.addNotificationItem(data)), [dispatch]);

  const invokeDeleteMessages = useCallback(func => dispatch(NotificationsAction.addInvokeFunction(func)), [dispatch]);

  const getNotificationList = useCallback(() =>
    dispatch(NotificationsAction.getNotificationList(NotificationsService.getNotificationList(user.userId)), [
      dispatch,
      user
    ])
  );

  const {
    CanCustomersCreate,
    CanCustomersRead,
    CanCustomersUpdate,
    CanUsersCreate,
    CanUsersRead,
    CanUsersUpdate,
    CanCalendarCreate,
    CanCalendarRead,
    CanCalendarUpdate,
    CanOrdersCreate,
    CanOrdersRead,
    CanProductsRead,
    CanExpensesRead,
    CanExpensesCreate,
    CanTasksCreate,
    CanTasksRead,
    CanTasksUpdate,
    CanAccountsRead,
    CanReportsRead
  } = user.permissions || {};

  const getSubString = str => {
    let text = '';
    const strToArr = str.split(' ');
    const link = strToArr.pop();
    text = strToArr.join(' ');
    return text;
  };

  useEffect(() => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_NOTIFICATION_HUB_LINK}`, {
        useDefaultPath: false,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => localStorage.getItem('jwtToken')
      })
      .configureLogging(LogLevel.Information)
      .build();

    const invoke = idx => {
      hubConnection
        .invoke('DeleteMessage', idx)
        .then(() => {
          getNotificationList();
        })
        .catch(err => {
          toastr.error('Помилка', err);
        });
    };

    hubConnection
      .start()
      .then(() => {
        invokeDeleteMessages(invoke);
      })
      .catch(err => {
        toastr.error('Помилка', err);
      });

    hubConnection.on('SendCreatedMessage', message => {
      console.log('object', message);
      if ((user.roleId === 2 || user.roleId === 3) && message.content.includes('Для Вас створено')) {
        console.log('');
      } else {
        toastr.success('Успіх', getSubString(message.content));
      }
      postMessage(message);
    });
  }, []);

  React.useMemo(() => {
    const onGetCurrentuser = user.userId ? onGetCurrentUser : () => {};
    onGetCurrentuser();
  }, [onGetCurrentUser]);

  React.useMemo(() => {
    const onGetNotificationList = user.userId ? getNotificationList : () => {};
    onGetNotificationList();
  }, [getNotificationList]);

  return (
    <MuiThemeProvider theme={theme}>
      <Switch>
        <Route exact path="/forget-password" component={WaitingComponent(Login)} />
        <Route exact path="/login" component={WaitingComponent(Login)} />
        <Route exact path="/confirmregistration" component={WaitingComponent(EmailConfirmation)} />
        <Route exact path="/changepassword" component={WaitingComponent(ResetPassword)} />
        <PrivateRoute exact path="/" component={WaitingComponent(Home)} />
        <Route exact path="/poc" component={WaitingComponent(Poc)} />
        <PrivateRoute exact path="/config" component={WaitingComponent(Config)} />
        <PrivateRoute exact path="/dashboard" component={WaitingComponent(Dashboard)} />
        <PrivateRoute exact path="/trackings" component={WaitingComponent(TrackingsContainer)} />
        <PrivateRoute exact path="/trackings/:id/view/:managerId" component={WaitingComponent(SubTrackingsContainer)} />

        <PrivateRoute exact path="/warehouse" component={WaitingComponent(Warehouse)} />
        <PrivateRoute exact path="/bonuses" component={WaitingComponent(Bonuses)} />
        <PrivateRoute exact path="/calendar" component={WaitingComponent(Calendar)} />
        <PrivateRoute
          exact
          path="/tasks/:taskId/task-history/:id/edit"
          component={WaitingComponent(NewEditTaskHistory)}
        />

        <PrivateRoute exact path="/accounts" component={CanAccountsRead ? WaitingComponent(Accounts) : NotFound} />
        <PrivateRoute exact path="/tasks" component={CanTasksRead ? WaitingComponent(Tasks) : NotFound} />
        <PrivateRoute exact path="/tasks/new" component={CanTasksCreate ? WaitingComponent(NewEditTask) : NotFound} />
        <PrivateRoute
          exact
          path="/tasks/:id/edit"
          component={CanTasksUpdate ? WaitingComponent(NewEditTask) : NotFound}
        />
        <PrivateRoute exact path="/costs" component={CanExpensesRead ? WaitingComponent(Costs) : NotFound} />
        <PrivateRoute
          exact
          path="/costs/new"
          component={CanExpensesCreate ? WaitingComponent(NewEditCosts) : NotFound}
        />

        <PrivateRoute exact path="/costs/:id/edit" component={WaitingComponent(NewEditCosts)} />
        <PrivateRoute exact path="/clients" component={CanCustomersRead ? WaitingComponent(Clients) : NotFound} />
        <PrivateRoute
          exact
          path="/clients/new"
          component={CanCustomersCreate ? WaitingComponent(NewEditClient) : NotFound}
        />
        <PrivateRoute exact path="/clients/:id/edit" component={WaitingComponent(NewEditClient)} />
        <PrivateRoute exact path="/orders" component={CanOrdersRead ? WaitingComponent(Orders) : NotFound} />
        <PrivateRoute
          exact
          path="/orders/new"
          component={CanOrdersCreate ? WaitingComponent(NewEditOrder) : NotFound}
        />
        <PrivateRoute exact path="/orders/:id/edit" component={WaitingComponent(NewEditOrder)} />
        <PrivateRoute
          exact
          path="/clients/:id/edit"
          component={CanCustomersUpdate ? WaitingComponent(NewEditClient) : NotFound}
        />
        <PrivateRoute exact path="/users" component={CanUsersRead ? WaitingComponent(Users) : NotFound} />
        <PrivateRoute exact path="/users/new" component={CanUsersCreate ? WaitingComponent(NewEditUser) : NotFound} />
        <PrivateRoute
          exact
          path="/users/:id/edit"
          component={CanUsersUpdate ? WaitingComponent(NewEditUser) : NotFound}
        />
        <PrivateRoute exact path="/calendar" component={CanCalendarRead ? WaitingComponent(Calendar) : NotFound} />
        <PrivateRoute
          exact
          path="/calendar/new"
          component={CanCalendarCreate ? WaitingComponent(NewEditCalendar) : NotFound}
        />
        <PrivateRoute
          exact
          path="/calendar/:id/edit"
          component={CanCalendarUpdate ? WaitingComponent(NewEditCalendar) : NotFound}
        />
        <PrivateRoute exact path="/products" component={CanProductsRead ? WaitingComponent(Products) : NotFound} />
        <PrivateRoute exact path="/warehouse-orders/:id/view" component={WaitingComponent(NewEditWarehouseOrder)} />
        <PrivateRoute exact path="/warehouse-orders" component={WaitingComponent(WarehouseOrder)} />
        <PrivateRoute exact path="/reports" component={CanReportsRead ? WaitingComponent(Reports) : NotFound} />
        <Route component={NotFound} />
      </Switch>
    </MuiThemeProvider>
  );
}

export default App;
