import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Actions
import AuthActions from '../../../store/actions/auth';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(1) * 3
  },
  itemText: {
    paddingLeft: theme.spacing(1) * 0
  },
  itemLink: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  activeNavLink: {
    '& div': {
      backgroundColor: 'rgba(0.0, 0, 0.0, 0.144)',
      '& div': {
        backgroundColor: 'rgba(0.0, 0, 0.0, 0)'
      }
    }
  }
}));

const localStorageKeys = {
  bonuses: 'bonuses-filters',
  accounts: 'accounts-filters',
  dashboard: 'saleProducts-filters',
  trackings: 'tracking-filters',
  products: 'products-filters',
  clients: 'clients-filters',
  'warehouse-orders': 'warehouse-orders-filters',
  orders: 'orders-filters',
  tasks: 'tasks-filters',
  costs: 'costs-filters',
  reports: 'reports-filters',
  calendar: 'calendar-filters',
  warehouse: 'warehouse-filters'
};

const SidebarItem = props => {
  const classes = useStyles();
  const { pathname, linkText, children } = props;
  const location = useLocation();

  const dispatch = useDispatch();

  const handleNavigateCLick = path => () => {
    if (path === location.pathname) return;

    const key = location.pathname.split('/')[1];
    if (!path.includes(key)) {
      localStorage.removeItem(localStorageKeys[key]);
    }

    if (path === '/login') {
      Object.values(localStorageKeys).forEach(item => localStorage.removeItem(item));
      dispatch(AuthActions.logoutUser());
    }
  };

  return (
    <NavLink to={{ pathname }} activeClassName={classes.activeNavLink} className={classes.itemLink}>
      <ListItem button className={classes.nested} onClick={handleNavigateCLick(pathname)}>
        <ListItemIcon>{children}</ListItemIcon>
        <ListItemText className={classes.itemText} inset primary={linkText} />
      </ListItem>
    </NavLink>
  );
};

SidebarItem.propTypes = {
  pathname: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default SidebarItem;
