import { GPS_LIST } from '../actions/types';

const initialState = [
  {
    id: 1,
    userId: 1,
    workId: 1,
    lat: 49.836958,
    lng: 24.033533
  },
  {
    id: 2,
    userId: 1,
    workId: 1,
    lat: 49.838278,
    lng: 24.032719
  },
  {
    id: 3,
    userId: 1,
    workId: 1,
    lat: 49.838166,
    lng: 24.032137
  },
  {
    id: 4,
    userId: 1,
    workId: 1,
    lat: 49.838018,
    lng: 24.032155
  },
  {
    id: 5,
    userId: 1,
    workId: 1,
    lat: 49.837921,
    lng: 24.031498
  }
];

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GPS_LIST: {
      return action.worksData;
    }
    default:
      return state;
  }
}
