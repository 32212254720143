import {
  GET_EVENTS,
  GET_EVENTS_BY_USER_ID,
  GET_EVENT_BY_ID,
  RESET_EVENT,
  DELETE_EVENT_BY_ID,
  POST_EVENT,
  PUT_EVENT,
  GET_ADRESSES_BY_CLIENT_ID
} from '../actions/types';

const initialValue = () => {
  return {
    name: '',
    city: '',
    typeId: 0,
    customerId: 0,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    userId: 0,
    isCompleted: false,
    notes: '',
    lat: 0,
    lng: 0
  };
};

const initialState = () => {
  return {
    list: [],
    value: initialValue()
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_EVENTS: {
      return { ...state, ...{ list: action.eventsData } };
    }
    case GET_EVENTS_BY_USER_ID: {
      return { ...state, ...{ list: action.eventsData } };
    }
    case GET_EVENT_BY_ID: {
      return { ...state, value: { ...action.data } };
    }
    case DELETE_EVENT_BY_ID: {
      return { ...state, ...action.data };
    }
    case POST_EVENT: {
      return { ...state, ...{ post: action.data } };
    }
    case RESET_EVENT: {
      return initialState();
    }
    case PUT_EVENT: {
      return { ...state, ...{ put: action.data } };
    }
    default:
      return state;
  }
}

export const clientAddresses = (state = { list: [] }, action = {}) => {
  switch (action.type) {
    case GET_ADRESSES_BY_CLIENT_ID: {
      return { ...state, ...{ list: action.data } };
    }

    default:
      return state;
  }
};
