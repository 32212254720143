import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  avatar: {
    margin: 10
  }
});

const UserLogo = () => {
  const classes = useStyles();
  const user = useSelector(state => state.currentUser);
  return (
    <Grid container justify="center" alignItems="center">
      <Avatar
        alt="avatar"
        src={user.avatar ? `data:image/jpeg;base64,${user.avatar}` : null}
        className={classes.avatar}
      />
      <p>
        {user.firstName} {user.lastName}
      </p>
    </Grid>
  );
};

export default UserLogo;
