import jwt_decode from 'jwt-decode';
import { SET_CURRENT_USER, LOGOUT_USER } from './types';
import setAuthToken from '../../utils/setAuthToken';
import AuthService from '../services/auth';
import ErrorsActions from './errors';
import { uiStartLoading, uiStopLoading } from './ui';
import NotificationsAction from './notification';

export default class AuthActions {
  static setCurrentUser = decoded => {
    return {
      type: SET_CURRENT_USER,
      payload: decoded
    };
  };

  static logoutUserHandler = () => {
    return {
      type: LOGOUT_USER
    };
  };

  static loginUser = (userData, authService) => async dispatch => {
    dispatch(uiStartLoading());
    try {
      const data = await authService(userData);
      dispatch(ErrorsActions.clearError());
      dispatch(uiStopLoading());
      // Save to localStorage
      const { bearerToken } = data;
      // Set token to ls
      localStorage.setItem('jwtToken', bearerToken);
      // Set token to Auth header
      setAuthToken(bearerToken);
      // Decode token to get user data
      const decode = jwt_decode(bearerToken);
      // Set current user
      dispatch(AuthActions.setCurrentUser(decode));
    } catch (err) {
      const errMsg = {
        success: false
      };
      dispatch(uiStopLoading());
      dispatch(ErrorsActions.raisError(errMsg));
    }
  };

  static logoutUser = () => dispatch => {
    localStorage.getItem('jwtToken');
    // Remove token from localStorage
    localStorage.removeItem('jwtToken');
    // Remove auth header for future requests
    setAuthToken(false);
    // Set current user to {} witch will set isAuthenticated to false
    dispatch(AuthActions.logoutUserHandler());
    dispatch(NotificationsAction.receiveNotificationList([]));
  };
}
