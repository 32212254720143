import { GET_PRODUCTS, GET_PRODUCTS_BY_CATEGORY_ID, POST_PRODUCT, PUT_PRODUCT } from '../actions/types';

const initialState = {
  list: []
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PRODUCTS: {
      return { ...state, ...{ list: action.productsData } };
    }

    case GET_PRODUCTS_BY_CATEGORY_ID: {
      return { ...state, ...{ list: action.productsData } };
    }
    case POST_PRODUCT: {
      return { ...state, ...{ post: action.data } };
    }
    case PUT_PRODUCT: {
      return { ...state, ...{ put: action.data } };
    }
    default:
      return state;
  }
}
