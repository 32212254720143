import { GET_MANAGERS, RESET_MANAGERS } from '../actions/types';

const initialState = () => {
  return [];
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_MANAGERS: {
      return [...action.managersData];
    }
    case RESET_MANAGERS: {
      return { ...initialState() };
    }
    default:
      return state;
  }
}
