import { WORKS_LIST } from '../actions/types';

// const initialState = [{ id: 1, name: 'work1' }, { id: 2, name: 'work2' }, { id: 3, name: 'work3' }];

export default function(state = [], action = {}) {
  switch (action.type) {
    case WORKS_LIST: {
      return action.worksData;
    }
    default:
      return state;
  }
}
