export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_WORKS_LIST = 'GET_WORKS_LIST';
export const WORKS_LIST = 'WORKS_LIST';
export const UI_START_LOADING = 'UI_START_LOADING';
export const UI_STOP_LOADING = 'UI_STOP_LOADING';
export const GET_ERRORS = 'GET_ERRORS';
export const GPS_LIST = 'GPS_LIST';
export const GET_GPS_LIST = 'GET_GPS_LIST';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENT_TYPES = 'GET_CLIENT_TYPES';
export const GET_CLIENT_PRODUCTS = 'GET_CLIENT_PRODUCTS';
export const GET_CLIENT_CULTURES = 'GET_CLIENT_CULTURES';
export const GET_CLIENT_CROP_ROTATIONS = 'GET_CLIENT_CROP_ROTATIONS';
export const GET_YEARS_LIST = 'GET_YEARS_LIST';
export const GET_YEARS_HARVEST_LIST = 'GET_YEARS_HARVEST_LIST';
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const GET_REGIONS = 'GET_REGIONS';
export const GET_CULTURES = 'GET_CULTURES';
export const GET_AREAS = 'GET_AREAS';
export const GET_AREAS_BY_REGION_ID = 'GET_AREAS_BY_REGION_ID';
export const GET_PRODUCTS_BY_CATEGORY_ID = 'GET_PRODUCTS_BY_CATEGORY_ID';
export const GET_ADDRESS_TYPES = 'GET_ADDRESS_TYPES';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDER_STATUSES = 'GET_ORDER_STATUSES';
export const GET_ORDER_TYPES = 'GET_ORDER_TYPES';
export const RESET_ORDER = 'RESET_ORDER';
export const GET_ORDER_PERIODS = 'GET_ORDER_PERIODS';
export const GET_FIRST_CLIENT_BY_MANAGER_ID = 'GET_FIRST_CLIENT_BY_MANAGER_ID';
export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_DELIVERY_TYPE = 'GET_DELIVERY_TYPE';
export const GET_COSTS = 'GET_COSTS';
export const GET_BILLS = 'GET_BILLS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_CLIENTS_BY_MANAGER_ID = 'GET_CLIENTS_BY_MANAGER_ID';
export const GET_CLIENT_BY_ID = 'GET_CLIENT_BY_ID';
export const GET_FILES_BY_ORDER_ID = 'GET_FILES_BY_ORDER_ID';
export const GET_MANAGERS = 'GET_MANAGERS';
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const POST_CLIENT = 'POST_CLIENT';
export const POST_ORDER = 'POST_ORDER';
export const POST_USER = 'POST_USER';
export const PUT_CLIENT = 'PUT_CLIENT';
export const PUT_ORDER = 'PUT_ORDER';
export const PUT_USER = 'PUT_USER';
export const GET_EVENT_TYPES = 'GET_EVENT_TYPES';
export const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID';
export const POST_EVENT = 'POST_EVENT';
export const PUT_EVENT = 'PUT_EVENT';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_BY_USER_ID = 'GET_EVENTS_BY_USER_ID';
export const GET_EVENT_BLANK = 'GET_EVENT_BLANK';
export const RESET_EVENT = 'RESET_EVENT';
export const DELETE_EVENT_BY_ID = 'DELETE_EVENT_BY_ID';
export const GET_MANAGERS_CLIENTS = 'GET_MANAGERS_CLIENTS';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CULTURES_HISTORY = 'GET_CULTURES_HISTORY';
export const GET_UNIT_OF_MEASURE_LIST = 'GET_UNIT_OF_MEASURE_LIST';
export const RESET_EVENT_TYPES = 'RESET_EVENT_TYPES';
export const RESET_MANAGER_CLIENTS = 'RESET_MANAGER_CLIENTS';
export const RESET_MANAGERS = 'RESET_MANAGERS';
export const RESET_ORDER_TYPES = 'RESET_ORDER_TYPES';
export const RESET_CLIENTS_BY_MANAGER_ID = 'RESET_CLIENTS_BY_MANAGER_ID';
export const GET_CLIENT_BANK_REQUISITES = 'GET_CLIENT_BANK_REQUISITES';
export const RESET_CLIENT_BANK_REQUISITES = 'RESET_CLIENT_BANK_REQUISITES';
export const RESET_FILES_BY_ORDER_ID = 'RESET_FILES_BY_ORDER_ID';
export const GET_TASKS = 'GET_TASKS';
export const GET_TASKS_TYPES_LIST = 'GET_TASKS_TYPES_LIST';
export const GET_TASKS_STATUSES_LIST = 'GET_TASKS_STATUSES_LIST';
export const GET_TASK_BY_ID = 'GET_TASK_BY_ID';
export const RESET_TASKS = 'RESET_TASKS';
export const GET_TASKS_RELATED_OPTION_LIST = 'GET_TASKS_RELATED_OPTION_LIST';
export const RESET_TASKS_RELATED_OPTION = 'RESET_TASKS_RELATED_OPTION';
export const GET_TASK_HISTORY_BY_ID = 'GET_TASK_HISTORY_BY_ID';
export const POST_TASK_HISTORY = 'POST_TASK_HISTORY';
export const GET_TRACKINGS = 'GET_TRACKINGS';
export const GET_BONUSES = 'GET_BONUSES';
export const POST_TASK = 'POST_TASK';
export const PUT_TASK = 'PUT_TASK';
export const RESET_TASK = 'RESET_TASK';
export const GET_TASK_FROM_MEMORY = 'GET_TASK_FROM_MEMORY';
export const POST_TASK_INTO_MEMORY = 'POST_TASK_INTO_MEMORY';
export const RESET_TASK_FROM_MEMORY = 'RESET_TASK_FROM_MEMORY';
export const GET_WAREHOUSE_LIST = 'GET_WAREHOUSE_LIST';
export const GET_WAREHOUSE_DATA = 'GET_WAREHOUSE_DATA';
export const GET_USERS = 'GET_USERS';
export const RESET_USER = 'RESET_USER';
export const GET_USER_ROLES_LIST = 'GET_USER_ROLES_LIST';
export const RESET_USER_ROLES_LIST = 'RESET_USER_ROLES_LIST';
export const GET_COST_BY_ID = 'GET_COST_BY_ID';
export const POST_COST = 'POST_COST';
export const PUT_COST = 'PUT_COST';
export const RESET_COST = 'RESET_COST';
export const GET_COST_DASHBOARD = 'GET_COST_DASHBOARD';
export const POST_PRODUCT = 'POST_PRODUCT';
export const PUT_PRODUCT = 'PUT_PRODUCT';
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';
export const RESET_ORDERS = 'RESET_ORDERS';
export const GET_TASKS_DASHBOARD = 'GET_TASKS_DASHBOARD';
export const GET_COSTS_CATEGORY_LIST = 'GET_COSTS_CATEGORY_LIST';
export const GET_COSTS_FILES_BY_ORDER_ID = 'GET_COSTS_FILES_BY_ORDER_ID';
export const RESET_COSTS_FILES_BY_ORDER_ID = 'RESET_COSTS_FILES_BY_ORDER_ID';
export const RESET_COSTS_CATEGORY_LIST = 'RESET_COSTS_CATEGORY_LIST';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNT_DASHBOARD = 'GET_ACCOUNT_DASHBOARD';
export const GET_ACCOUNTS_TYPES_LIST = 'GET_ACCOUNTS_TYPES_LIST';
export const RESET_ACCOUNTS = 'RESET_ACCOUNTS';
export const GET_SOLD_STATISTIC_DASHBOARD = 'GET_SOLD_STATISTIC_DASHBOARD';
export const GET_SALE_LIDERS = 'GET_SALE_LIDERS';
export const GET_CLIENT_SORTING_ORDER_LIST = 'GET_CLIENT_SORTING_ORDER_LIST';
export const GET_PRODUCTS_AT_WAREHOUSE = 'GET_PRODUCTS_AT_WAREHOUSE';
export const GET_PRODUCT_CATEGORIES_GRID = ' GET_PRODUCT_CATEGORIES_GRID';
export const GET_ADRESSES_BY_CLIENT_ID = 'GET_ADRESSES_BY_CLIENT_ID';
export const GET_PRODUCT_REPORT = 'GET_PRODUCT_REPORT';
export const GET_CULTURE_REPORT = 'GET_CULTURE_REPORT';
export const GET_CLIENT_REPORT = 'GET_CLIENT_REPORT';
export const GET_TASK_TYPES_GRID = 'GET_TASK_TYPES_GRID';
export const GET_COST_TYPES_GRID = 'GET_COST_TYPES_GRID';
export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ADD_INVOKE_FUNCTION = 'ADD_INVOKE_FUNCTION';
