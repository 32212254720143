// import parseISO from 'date-fns/parseISO';
import {
  GET_TASKS,
  GET_TASK_BY_ID,
  POST_TASK,
  PUT_TASK,
  RESET_TASK,
  GET_TASKS_DASHBOARD,
  UI_START_LOADING,
  UI_STOP_LOADING
} from '../actions/types';

const initialValue = () => {
  return {
    name: '',
    userTaskTypeId: 0,
    planDescription: '',
    planQuantity: 0,
    userTaskStatusId: 0,
    deadline: new Date().toISOString(),
    userId: 0,
    file: [],
    yearId: 4,
    userTaskRelatedWithId: 0,
    userTaskCultures: []
  };
};

const initialState = () => {
  return {
    list: [],
    value: initialValue(),
    dashboard: [],
    isLoading: false
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_TASKS: {
      return { ...state, ...{ list: action.data } };
    }
    case GET_TASKS_DASHBOARD: {
      return { ...state, ...{ dashboard: action.data } };
    }
    case GET_TASK_BY_ID: {
      const data = action.data.fileName
        ? [{ id: 532525, fileName: action.data.fileName, url: action.data.fileUrl }]
        : [];
      return {
        ...state,
        ...{
          value: { ...action.data, file: data }
        }
      };
    }
    case POST_TASK: {
      return { ...state, ...{ post: action.data } };
    }
    case PUT_TASK: {
      return { ...state, ...{ put: action.data } };
    }

    case RESET_TASK: {
      return { ...state, ...{ value: initialValue() } };
    }
    case UI_START_LOADING: {
      return { ...state, isLoading: true };
    }
    case UI_STOP_LOADING: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
}
