import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: '#efffef',
    border: '1px solid #A8A8A8',
    padding: 16
  }
}));

export default function NoInternetModal() {
  const classes = useStyles();

  const [isDisconnected, setIsDisconected] = useState(false);

  useEffect(() => {
    console.log(isDisconnected);
  }, [isDisconnected]);

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors'
        })
          .then(() => {
            setIsDisconected(false);
            return clearInterval(webPing);
          })
          .catch(() => setIsDisconected(true));
      }, 2000);
      return;
    }

    return setIsDisconected(true);
  };

  useEffect(() => {
    handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
    return function cleanup() {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  });

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={isDisconnected}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
    >
      <div className={classes.paper}>
        <h2 id="server-modal-title">Відсутнє підключення до інтернету!</h2>
        <p id="server-modal-description">Перевірте чи маєте підключення до інтернету і продовжте роботу</p>
      </div>
    </Modal>
  );
}
