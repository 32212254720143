import {
  GET_ORDERS,
  RESET_ORDERS,
  GET_ORDER_BY_ID,
  RESET_ORDER,
  POST_ORDER,
  PUT_ORDER,
  UI_START_LOADING,
  UI_STOP_LOADING,
  LOGOUT_USER
} from '../actions/types';

const initialValue = () => {
  return {
    managerId: 0,
    allowNextStep: false,
    orderTypeId: 1,
    clientId: 0,
    creationDate: new Date().toISOString(),
    deliveryDate: new Date().toISOString(),
    paymentDetailsId: 0,
    paymentConditionId: 1,
    paymentConditionComments: '',
    files: [],
    comments: '',
    clientProducts: [],
    orderDeliveries: []
  };
};

const initialState = () => {
  return {
    list: [],
    value: initialValue(),
    isLoading: false,
    put: null,
    post: null,
    editStatus: false
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_ORDERS: {
      return { ...state, ...{ list: action.ordersData.data }, ...{ totalAmount: action.ordersData.totalAmount } };
    }
    case RESET_ORDERS: {
      return { ...state, ...{ list: [] } };
    }
    case GET_ORDER_BY_ID: {
      return { ...state, value: { ...action.data } };
    }
    case RESET_ORDER: {
      return { ...state, value: initialValue() };
    }
    case POST_ORDER: {
      return { ...state, post: action.data, editStatus: true };
    }
    case PUT_ORDER: {
      return { ...state, put: action.data, editStatus: true };
    }
    case UI_START_LOADING: {
      return { ...state, isLoading: true, editStatus: false };
    }
    case UI_STOP_LOADING: {
      return { ...state, isLoading: false };
    }
    case LOGOUT_USER: {
      return { ...initialState() };
    }
    default:
      return state;
  }
}
