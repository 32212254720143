import React from 'react';
import List from '@material-ui/core/List';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MapIcon from '@material-ui/icons/Map';
import PersonIcon from '@material-ui/icons/Person';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import MonetizationIcon from '@material-ui/icons/MonetizationOnOutlined';
import HomeIcon from '@material-ui/icons/Home';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import FilterBAndWOutlinedIcon from '@material-ui/icons/FilterBAndWOutlined';
import { useSelector } from 'react-redux';

// Components
import SidebarItem from './SidebarItem';

const SidebarItems = () => {
  const user = useSelector(state => state.auth.user);

  const {
    CanDashboardRead,
    CanCustomersRead,
    CanUsersRead,
    CanCalendarRead,
    CanOrdersRead,
    CanProductsRead,
    CanAccountsRead,
    CanTasksRead,
    CanWarehousesRead,
    CanOrderWarehouseRead,
    CanRouteRead,
    CanExpensesRead,
    CanReportsRead
  } = user.permissions || {};

  return (
    <List component="div" disablePadding>
      {CanDashboardRead && (
        <SidebarItem pathname="/dashboard" linkText="Підсумки">
          <DashboardIcon />
        </SidebarItem>
      )}
      {CanTasksRead && (
        <SidebarItem pathname="/tasks" linkText="Завдання">
          <AssignmentIcon />
        </SidebarItem>
      )}
      {CanProductsRead && (
        <SidebarItem pathname="/products" linkText="Продукти">
          <FilterBAndWOutlinedIcon />
        </SidebarItem>
      )}
      {CanCalendarRead && (
        <SidebarItem pathname="/calendar" linkText="Календар">
          <CalendarTodayIcon />
        </SidebarItem>
      )}

      {CanExpensesRead && (
        <SidebarItem pathname="/costs" linkText="Витрати">
          <MonetizationIcon />
        </SidebarItem>
      )}
      {CanRouteRead && (
        <SidebarItem pathname="/trackings" linkText="Маршрути">
          <MapIcon />
        </SidebarItem>
      )}

      {CanCustomersRead && (
        <SidebarItem pathname="/clients" linkText="Клієнти">
          <PersonIcon />
        </SidebarItem>
      )}
      {CanOrdersRead && (
        <SidebarItem pathname="/orders" linkText="Замовлення">
          <WorkOutlineIcon />
        </SidebarItem>
      )}
      {CanUsersRead && (
        <SidebarItem pathname="/users" linkText="Користувачі">
          <PersonIcon />
        </SidebarItem>
      )}
      {CanAccountsRead && (
        <SidebarItem pathname="/accounts" linkText="Рахунки">
          <MonetizationIcon />
        </SidebarItem>
      )}
      {CanWarehousesRead && (
        <SidebarItem pathname="/warehouse" linkText="Склад">
          <HomeIcon />
        </SidebarItem>
      )}
      {CanOrderWarehouseRead && (
        <SidebarItem pathname="/warehouse-orders" linkText="Замовлення на складі">
          <WorkOutlineIcon />
        </SidebarItem>
      )}
      {CanReportsRead && (
        <SidebarItem pathname="/reports" linkText="Звіти">
          <AssignmentIcon />
        </SidebarItem>
      )}

      <SidebarItem pathname="/config" linkText="Налаштування">
        <MoreHorizIcon />
      </SidebarItem>
      <SidebarItem pathname="/login" linkText="Вихід">
        <PowerSettingsNewIcon />
      </SidebarItem>
    </List>
  );
};

export default SidebarItems;
