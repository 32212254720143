// import parseISO from 'date-fns/parseISO';
import { GET_USERS, GET_USER_BY_ID, POST_USER, PUT_USER, RESET_USER } from '../actions/types';
import userDefaultImage from '../../utils/userDefaultImage';

const initialValue = () => {
  return {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    carNumber: '',
    email: '',
    carModel: '',
    employment: '',
    avatar: userDefaultImage(),
    userRegions: [
      {
        regionId: 13,
        areaId: 270,
        userId: 0
      }
    ],
    userWarehouse: null,
    roleId: 0,
    isDeleted: true
  };
};

const initialState = () => {
  return {
    list: [],
    value: initialValue()
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_USERS: {
      return { ...state, ...{ list: action.data } };
    }
    case GET_USER_BY_ID: {
      return {
        ...state,
        ...{
          value: { ...action.data }
        }
      };
    }
    case POST_USER: {
      return { ...state, ...{ post: action.data } };
    }
    case PUT_USER: {
      return { ...state, ...{ put: action.data } };
    }

    case RESET_USER: {
      return { ...state, ...{ value: initialValue() } };
    }
    default:
      return state;
  }
}
