export class ServicesConfig {
  static baseURL = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_SERVER ||
    'api.staging.tdlemberg.com'}:${process.env.REACT_APP_PORT || '443'}`;

  static authEndpoint = '/login';

  static worksEndpoint = '/works';

  static gpsEndpoint = '/api/v1/gps';

  static clientsEndpoint = '/clients';

  static clientProductsEndpoint = '/clientproducts';

  static clientCulturesEndpoint = '/client-сultures/request';

  static yearsEndpoint = '/years';

  static yearsHarverstEndpoint = '/years-harvest';

  static productCategoriesEndpoint = '/product-categories/list';

  static productCategoriesSingleEndpoint = '/product-categories';

  static clientTypesEndpoint = '/client-types';

  static regionsEndpoint = '/regions';

  static culturesEndpoint = '/cultures';

  static areasEndpoint = '/areas';

  static addressTypesEndpoint = '/addresstypes';

  static orderStatusesEndpoint = '/order-statuses';

  static orderTypesEndpoint = '/order-types';

  static currenciesEndpoint = '/currencies';

  static orderPeriodsEndpoint = '/order-periods';

  static ordersEndpoint = '/orders';

  static productsEndpoint = '/products';

  static singleOrderEndpoint = '/orders';

  static singleManagerClientsEndpoint = '/clients-details';

  static clintAddressesEndpoint = '/client-addresses';

  static clintPaymentEndpoint = '/client-payments';

  static singleUserEndpoint = '/users';

  static managersEndpoint = '/sales-managers';

  static singleFileEndpoint = '/order-files';

  static filesByOrderEndpoint = '/order-files';

  static eventTypesEndpoint = '/user-events/types';

  static singleEventEndpoint = '/user-events';

  static eventsEndpoint = '/user-events';

  static managersClientsEndpoint = '/clients-manager';

  static currentUserEndpoint = '/users';

  static products = '/products';

  static culturesHistoryEndpoint = '/orders/clients';

  static unitOfMeasureEndpoint = '/unitofmeasures';

  static updateResult = '/client-cultures';

  static tasksEndpoint = '/tasks';

  static taskDetailEndpoint = '/task-detail';

  static taskEndpoint = '/user-tasks';

  static tasksTypesEndpoint = '/user-task-types/list';

  static tasksStatusesEndpoint = '/task-statuses';

  static tasksRelatedOptionEndpoint = '/task-related-options';

  static taskHistoriesEndpoint = '/task-cultures';

  static taskHistoryEndpoint = '/task-history';

  static trakingsByManagerIdEndpoint = '/trackings';

  static trakingsByDateEndpoint = '/trackingsbyDate';

  static bonusesEndpoint = '/users';

  static warehousesTypesEndpoint = '/warehouses';

  static warehousesEndpoint = '/warehouse-products';

  static ordersWarehouseEndpoint = '/orders-warehouses';

  static usersEndpoint = '/users';

  static userEndpoint = '/users';

  static userRolesEndpoint = '/all-roles';

  static costsEndpoint = '/expenses';

  static costDashboardEndpoint = '/expenses/dashboard';

  static orderStatusesUpdateEndpoint = '/orders-status-update';

  static tasksDashboardEndpoint = '/tasks/dashboard';

  static costsCategoryEndpoint = '/expense-categories/list';

  static costFileEndpoint = '/expense-files';

  static accountsEndpoint = '/accounts';

  static accountDashboardEndpoint = '/accounts';

  static accountsTypesEndpoint = '/accounts-types';

  static accountsExcel = '/accounts-excel';

  static soldStatisticDashboardEndpoint = '/client-products-sold-count';

  static saleLidersDashboardEndpoint = '/leaders-of-sales';

  static resetPasswordEndpoint = '/users/forgotpassword';

  static productsWarehouseEndpoint = '/warehouse-products';

  static checkUserSeeAllManagers = '/sales-managers-available';

  static checkUserUpdateTask = '/tasks-edit-permissions';

  static clientOrderSorting = '/clients/sorting-orders';

  static warehouseProductsEndpoint = '/warehouses-products';

  static putTastHistory = '/task-cultures-history';

  static putFileTastHistory = '/task-cultures-history-updateimage';

  static emailConfirmation = '/users/emails/confirmed';

  static forgotPasswordEndpoint = '/users/resetpassword';

  static excelExpenses = '/expenses-excel';

  static checkPasswordEndpoint = '/users-password';

  static clientAddressesById = '/users-password';

  static costsCategoryGridEndpoint = '/expense-categories';

  static tasksTypesGridEndpoint = '/user-task-types';

  static checkUsersEmail = '/users-email/check';

  static reportsEndpoint = '/reports';

  static notificationendpoint = 'messages';

  static excelAccounts = '/accounts-excel';

  static productsHistoryEndpoint = '/orders-clients-products';

  static clientsCulturesInfoAboutLands = '/clients-cultures/info-about-lands';
}
