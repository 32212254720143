import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: 'rgba(5, 124, 72, 1)'
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contast with palette.primary.main
    }
  },
  overrides: {
    MuiDialogContentText: {
      root: {
        color: 'rgba(77, 77, 79, 1)',
        fontSize: '1.5rem'
      }
    },
    MuiListSubheader: {
      sticky: {
        backgroundColor: 'rgba(255, 255, 255, 1)'
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: '1.2rem'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.2rem'
      }
    }
  },
  typography: {
    useNextVariants: true
  }
});
