import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import uiReducer from './ui';
import worksReducer from './works';
import gpsReducer from './gps';
import clientsReducer from './clients';
import clientCulturesReducer from './clientCultures';
import clientProductsReducer from './clientProducts';
import culturesReducer from './cultures';
import yearsReducer from './years';
import productCategoriesReducer, { productCategoryForGrid } from './productCategoties';
import clientTypesReducer from './clientTypes';
import regionsReducer from './regions';
import areasReducer from './areas';
import addressTypesReducer from './addressTypes';
import authReducer from './auth';
import errorReducer from './errors';
import orderStatusesReducer from './orderStatuses';
import orderTypesReducer from './orderTypes';
import eventTypesReducer from './eventTypes';
import currenciesReducer from './currencies';
import orderPeriodsReducer from './orderPeriods';
import ordersReducer from './orders';
import deliveryType from './deliveryType';
import orderFilesReducer from './orderFiles';
import singleManagerClientsReducer from './singleManagerClients';
import managersReducer from './managers';
import clientCropRotationsReducer from './clientCropRotations';
import eventsReducer, { clientAddresses } from './events';
import managersClientsReducer from './managersClients';
import currentUserReducer from './currentUser';
import productsReducer from './products';
import culturesHistoryReducer from './cultureHistory';
import unitOfMeasureReducer from './unitOfMeasure';
import clientBankRequisitesReducer from './clientBankRequisites';
import tasksReduser from './tasks';
import tasksTypesReduser, { tastTypesForGrid } from './tasksTypes';
import tasksStatusesReduser from './tasksStatuses';
import tasksRelatedOptionReducer from './tasksRelatedOption';
import taskHistoryReducer from './taskHistory';
import trackingsReducer from './trackings';
import bonusesReducer from './bonuses';
import taskMemoryReducer from './taskMemory';
import warehousesReducer from './warehouseType';
import warehousesDataReducer from './warehouses';
import usersReduser from './users';
import userRolesReducer from './userRoles';
import costsReducer from './costs';
import costsCategoryReducer, { costTypesForGrid } from './costsCategory';
import costsFilesReducer from './costsFiles';
import accountsReducer from './accounts';
import accountsTypesReducer from './accountsType';
import soldStatisticReducer from './soldStatistic';
import saleLidersReducer from './saleLiders';
import clientSortingOrderReducer from './clientOrder';
import productsAtWarehouseReducer from './productsAtWarehouse';
import reportReducer from './reports';
import notificationReducer from './notification';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    notifications: notificationReducer,
    toastr: toastrReducer,
    auth: authReducer,
    errors: errorReducer,
    ui: uiReducer,
    worksList: worksReducer,
    gpsList: gpsReducer,
    clients: clientsReducer,
    clientCropRotations: clientCropRotationsReducer,
    clientCultures: clientCulturesReducer,
    clientProducts: clientProductsReducer,
    years: yearsReducer,
    productCategories: productCategoriesReducer,
    clientTypes: clientTypesReducer,
    regions: regionsReducer,
    cultures: culturesReducer,
    areas: areasReducer,
    addressTypes: addressTypesReducer,
    orderStatuses: orderStatusesReducer,
    orderTypes: orderTypesReducer,
    currencies: currenciesReducer,
    orderPeriods: orderPeriodsReducer,
    orders: ordersReducer,
    trackings: trackingsReducer,
    orderFiles: orderFilesReducer,
    deliveryType,
    singleManagerClients: singleManagerClientsReducer,
    managers: managersReducer,
    eventTypes: eventTypesReducer,
    events: eventsReducer,
    managersClients: managersClientsReducer,
    currentUser: currentUserReducer,
    culturesHistory: culturesHistoryReducer,
    unitOfMeasure: unitOfMeasureReducer,
    clientBankRequisites: clientBankRequisitesReducer,
    tasks: tasksReduser,
    tasksTypes: tasksTypesReduser,
    tasksStatuses: tasksStatusesReduser,
    tasksRelatedOption: tasksRelatedOptionReducer,
    taskHistory: taskHistoryReducer,
    products: productsReducer,
    bonuses: bonusesReducer,
    taskMemory: taskMemoryReducer,
    warehouseType: warehousesReducer,
    warehousesData: warehousesDataReducer,
    users: usersReduser,
    userRoles: userRolesReducer,
    costs: costsReducer,
    costsCategory: costsCategoryReducer,
    costsFiles: costsFilesReducer,
    accounts: accountsReducer,
    accountsTypes: accountsTypesReducer,
    soldStatistic: soldStatisticReducer,
    saleLiders: saleLidersReducer,
    clientSortingOrder: clientSortingOrderReducer,
    productsAtWarehouse: productsAtWarehouseReducer,
    productCategoryForGrid: productCategoryForGrid,
    clientAddresses: clientAddresses,
    reports: reportReducer,
    taskTypesForGrid: tastTypesForGrid,
    costTypesForGrid: costTypesForGrid
  });

export default createRootReducer;
