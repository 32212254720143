import React from 'react';

function NotFound() {
  return (
    <div className="error-notification">
      <h1>
        <span>404</span>
        NotFound
      </h1>
    </div>
  );
}

export default NotFound;
