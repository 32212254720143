// import parseISO from 'date-fns/parseISO';
import { GET_SALE_LIDERS } from '../actions/types';

const initialState = () => {
  return {
    dashboard: []
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_SALE_LIDERS: {
      return { ...state, ...{ dashboard: action.data } };
    }
    default:
      return state;
  }
}
