import { GET_TRACKINGS, UI_START_LOADING, UI_STOP_LOADING } from '../actions/types';

// const initialState = {
//   list: [],
//   totalAmmount: 0
// };

const initialState = () => {
  return {
    list: [],
    totalAmmount: 2,
    isLoading: false
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_TRACKINGS: {
      return { ...state, ...{ list: action.data } };
    }
    case UI_START_LOADING: {
      return { ...state, isLoading: true };
    }
    case UI_STOP_LOADING: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
}
