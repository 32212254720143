// import parseISO from 'date-fns/parseISO';
import {
  GET_COSTS,
  GET_COST_BY_ID,
  POST_COST,
  PUT_COST,
  RESET_COST,
  GET_COST_DASHBOARD,
  UI_START_LOADING,
  UI_STOP_LOADING
} from '../actions/types';

const initialValue = () => {
  return {
    id: 0,
    name: '',
    categoryId: 0,
    clientId: 0,
    sum: 0,
    expenditureDate: new Date().toISOString(),
    userId: 0
  };
};

const initialState = () => {
  return {
    list: { pagedExpenses: {}, totalSum: 0 },
    value: initialValue(),
    dashboard: [],
    isLoading: false
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_COSTS: {
      return { ...state, ...{ list: action.data } };
    }
    case GET_COST_BY_ID: {
      return {
        ...state,
        ...{
          value: { ...action.data }
        }
      };
    }
    case GET_COST_DASHBOARD: {
      return { ...state, ...{ dashboard: action.data } };
    }

    case POST_COST: {
      return { ...state, ...{ post: action.data } };
    }
    case PUT_COST: {
      return { ...state, ...{ put: action.data } };
    }

    case RESET_COST: {
      return { ...state, ...{ value: initialValue() } };
    }
    case UI_START_LOADING: {
      return { ...state, isLoading: true };
    }
    case UI_STOP_LOADING: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
}
