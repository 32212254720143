// import parseISO from 'date-fns/parseISO';
import { GET_TASK_HISTORY_BY_ID, POST_TASK_HISTORY } from '../actions/types';

const initialState = () => {
  return {
    value: {
      userTaskCultureId: 0,
      historyResponses: [
        {
          name: '',
          userTaskCultureId: 0,
          applicationRate: 0,
          quantity: 0,
          dueDate: new Date().toISOString(),
          productId: 1,
          yearId: 3
        }
      ]
    }
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_TASK_HISTORY_BY_ID: {
      return { ...state, ...{ value: action.data } };
    }
    case POST_TASK_HISTORY: {
      return { ...state, ...{ post: action.data } };
    }
    default:
      return state;
  }
}
