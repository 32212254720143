import { GET_EVENT_TYPES, RESET_EVENT_TYPES } from '../actions/types';

const initialState = () => {
  return {
    list: []
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_EVENT_TYPES: {
      return { ...state, ...{ list: action.eventTypesData } };
    }
    case RESET_EVENT_TYPES: {
      return { ...initialState() };
    }
    default:
      return state;
  }
}
