import { GET_ERRORS } from './types';

export default class ErrorsActions {
  /**
   * Raise error action
   *
   * @static
   * @param {*} error
   * @returns
   * @memberof ErrorsActions
   */
  static raisError(error) {
    return function(dispatch) {
      dispatch({
        type: GET_ERRORS,
        payload: error
      });
    };
  }

  /**
   * Clean error action
   *
   * @static
   * @returns
   * @memberof ErrorsActions
   */
  static clearError() {
    return function(dispatch) {
      dispatch({
        type: GET_ERRORS,
        payload: {}
      });
    };
  }
}
