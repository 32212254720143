import {
  GET_CLIENTS,
  GET_CLIENT_BY_ID,
  POST_CLIENT,
  PUT_CLIENT,
  UI_START_LOADING,
  UI_STOP_LOADING
} from '../actions/types';
import { shortid } from '../../utils';

// const initialState = count => {
//   const list = [];
//   for (let i = 1; i <= count; i++) {
//     const obj = {
//       id: i,
//       name: `test${i}`,
//       type: i % 2 === 0 ? 'малий' : 'середній',
//       location: `test location ${i}`,
//       person: i % 2 === 0 ? 'Васильцев Василь' : 'Іванка Дмитрів',
//       number: `096 695 016${i}`
//     };
//     list.push(obj);
//   }
//   return { list };
// };

const initialState = () => {
  return {
    list: [],
    isLoading: false,
    value: {
      clientName: '',
      clientTypeId: 1,
      clientWebSite: '',
      clientAddresses: [
        {
          id: shortid.generate(),
          addressTypeId: 1,
          cityName: '',
          streetName: 'street',
          regionId: 13,
          areaId: 0,
          lat: 49.832037,
          lng: 24.033811
        }
      ],
      quantityOfLand: 0,
      clientPaymentDetails: [],
      clientContacts: [],
      beginningOfCooperation: new Date(),
      clientEvents: [],
      clientCultureLands: []
    }
  };
};

export default function(state = initialState(20), action = {}) {
  switch (action.type) {
    case GET_CLIENTS: {
      return { ...state, ...{ list: action.clientsData.data }, ...{ totalAmount: action.clientsData.totalAmount } };
    }
    case GET_CLIENT_BY_ID: {
      return { ...state, ...{ value: action.data } };
    }
    case POST_CLIENT: {
      return { ...state, ...{ post: action.data } };
    }
    case PUT_CLIENT: {
      return { ...state, ...{ put: action.data } };
    }
    case UI_START_LOADING: {
      return { ...state, isLoading: true };
    }
    case UI_STOP_LOADING: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
}
