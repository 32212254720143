import { GET_DELIVERY_TYPE } from '../actions/types';

const initialState = {
  list: [
    {
      id: 1,
      name: 'Самовивіз/Клієнтом'
    },
    {
      id: 2,
      name: 'Самовивіз/Менеджером'
    },
    {
      id: 3,
      name: 'Пошук транспорту'
    },
    {
      id: 4,
      name: 'Нова пошта'
    }
  ]
};
export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DELIVERY_TYPE: {
      return state;
    }
    default:
      return state;
  }
}
