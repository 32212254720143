// import parseISO from 'date-fns/parseISO';
import { GET_CLIENTS_BY_MANAGER_ID, LOGOUT_USER } from '../actions/types';

const initialState = () => {
  return {
    list: []
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_CLIENTS_BY_MANAGER_ID: {
      return { ...state, ...{ list: action.data } };
    }
    case LOGOUT_USER: {
      return { ...initialState() };
    }
    default:
      return state;
  }
}
