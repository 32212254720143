import { GET_CURRENT_USER } from './types';
import CurrentUserService from '../services/currentUser';

export default class CurrentUserActions {
  static receiveCurrentUser(data) {
    return {
      type: GET_CURRENT_USER,
      userData: data
    };
  }

  static getCurrentUser(userId) {
    return function(dispatch) {
      CurrentUserService.getCurrentUser(userId).then(data => {
        dispatch(CurrentUserActions.receiveCurrentUser(data));
      });
    };
  }
}
