import { GET_USER_ROLES_LIST, RESET_USER_ROLES_LIST } from '../actions/types';

const initialState = () => ({
  list: []
});

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_USER_ROLES_LIST: {
      return { ...state, ...{ list: action.userRolesData } };
    }
    case RESET_USER_ROLES_LIST: {
      return { ...initialState() };
    }
    default:
      return state;
  }
}
