import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import NoInternetModal from '../../../components/NoInternetModal/NoInternetModal';
import ApplicationBar from '../../../components/ApplicationBar/ApplicationBar';
import Sidebar from '../../../components/Sidebar/Sidebar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    minHeight: '700px',
    overflowY: 'scroll'
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    maxWidth: '100%'
  }
}));

const MainLayout = props => {
  const classes = useStyles();
  const { children } = props;

  return (
    <div className={classes.root}>
      <NoInternetModal />
      <ApplicationBar />
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer}>
          <Container className={classes.container}>{children}</Container>
        </div>
      </main>
    </div>
  );
};

export default MainLayout;
