import { GET_CURRENCIES } from '../actions/types';

const initialState = {
  list: []
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CURRENCIES: {
      return { ...state, ...{ list: action.currenciesData } };
    }
    default:
      return state;
  }
}
