import { GET_TASKS_STATUSES_LIST } from '../actions/types';

const initialState = {
  list: []
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_TASKS_STATUSES_LIST: {
      return { ...state, ...{ list: action.tasksStatusesData } };
    }
    default:
      return state;
  }
}
