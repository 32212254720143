// import parseISO from 'date-fns/parseISO';
import { GET_CULTURES_HISTORY } from '../actions/types';

const initialState = {
  list: []
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CULTURES_HISTORY: {
      return { ...state, ...{ list: action.data } };
    }
    default:
      return state;
  }
}
