import { GET_PRODUCT_CATEGORIES, GET_PRODUCT_CATEGORIES_GRID } from '../actions/types';

const initialState = {
  list: []
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PRODUCT_CATEGORIES: {
      return { ...state, ...{ list: action.productCategoriesData } };
    }
    default:
      return state;
  }
}

export const productCategoryForGrid = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PRODUCT_CATEGORIES_GRID: {
      return { ...state, ...{ list: action.productCategoriesData } };
    }
    default:
      return state;
  }
};
