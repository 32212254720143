import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import NotificationBar from './NotificationBar';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  grow: {
    flexGrow: 1
  }
}));

export default function ApplicationBar() {
  const classes = useStyles();

  const handleNotification = value => {
    setIsVisible(value);
  };

  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <NotificationBar visibility={isVisible} handleVisibilityChange={value => handleNotification(value)} />
      </AppBar>
    </div>
  );
}
