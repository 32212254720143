import { GET_CLIENT_CROP_ROTATIONS } from '../actions/types';
import { shortid } from '../../utils';

// const initialState = {
//   list: []
// };

const productNames = () => {
  const arr = [];
  const quantity = Math.floor(Math.random() * 10) + 1;
  for (let i = 1; i <= quantity; i++) {
    const obj = {
      id: shortid.generate(),
      name: i % 2 === 0 ? 'Tecamin Brix (1L)' : 'FERTIGRANT START COMO'
    };
    arr.push(obj);
  }
  return arr;
};

const itemObj = ammount => {
  const totalArea = Math.floor(Math.random() * 100) + 1;
  const areaUnderOurProducts = Math.floor(Math.random() * 100) + 1;
  return {
    id: shortid.generate(),
    cultureName: ammount % 2 === 0 ? 'Пшениця озима' : 'Пшениця яра',
    totalArea,
    areaUnderOurProducts,
    productName: productNames()
  };
};

const dataArr = ammount => {
  const arr = [];
  for (let i = 0; i < ammount; i++) {
    arr.push(itemObj(ammount));
  }
  return arr;
};

const initialState = count => {
  const tableData = [];
  for (let i = 1; i <= count; i++) {
    const obj = {
      id: shortid.generate(),
      year: `${2016 + i}`,
      data: dataArr(i)
    };
    tableData.push(obj);
  }
  return { list: tableData, totalAmount: count };
};

export default function(state = initialState(5), action = {}) {
  switch (action.type) {
    case GET_CLIENT_CROP_ROTATIONS: {
      return {
        ...state,
        ...{ list: action.clientHistoryOfRotationsData },
        ...{ totalAmount: action.clientCulturesData.totalAmount }
      };
    }
    default:
      return state;
  }
}
