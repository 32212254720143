import {
  GET_PRODUCT_REPORT,
  GET_CULTURE_REPORT,
  GET_CLIENT_REPORT,
  UI_START_LOADING,
  UI_STOP_LOADING
} from '../actions/types';

const initialState = {
  product: {},
  culture: {},
  client: {},
  isLoading: false
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PRODUCT_REPORT: {
      return { ...state, ...{ product: action.data } };
    }
    case GET_CULTURE_REPORT: {
      return { ...state, ...{ culture: action.data } };
    }
    case GET_CLIENT_REPORT: {
      return { ...state, ...{ client: action.data } };
    }
    case UI_START_LOADING: {
      return { ...state, isLoading: true };
    }
    case UI_STOP_LOADING: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
}
