import { GET_CLIENT_PRODUCTS } from '../actions/types';

const initialState = {
  list: []
};
// const initialState = count => {
//   const tableData = [];
//   for (let i = 1; i <= count; i++) {
//     const obj = {
//       id: i,
//       name: `test ${i}`,
//       category: i % 2 === 0 ? 'Листкові добрива' : 'Листкові добрива з захисною функцією',
//       average_price: i.toFixed(2),
//       ammount: i * 100,
//       unit: 'л'
//     };
//     tableData.push(obj);
//   }
//   return { tableData };
// };

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CLIENT_PRODUCTS: {
      return {
        ...state,
        ...{ list: action.clientProductsData },
        ...{ totalAmount: action.clientProductsData.totalAmount }
      };
    }
    default:
      return state;
  }
}
