import { ServicesConfig } from './config';
import { DataService } from './dataService';

export default class NotificationsService {
  static async getNotificationListData(userId) {
    const result = await DataService.sendRequest(`/${ServicesConfig.notificationendpoint}/${userId}`, 'GET');
    return result;
  }

  static getNotificationList(userId) {
    return async () => {
      const result = await NotificationsService.getNotificationListData(userId);
      return result.error ? result.error : result.data;
    };
  }
}
