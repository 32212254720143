import { GET_ACCOUNTS_TYPES_LIST, RESET_ACCOUNTS } from '../actions/types';

const initialState = () => {
  return {
    list: []
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_ACCOUNTS_TYPES_LIST: {
      return { ...state, ...{ list: action.accountsTypesData } };
    }
    case RESET_ACCOUNTS: {
      return { ...initialState() };
    }
    default:
      return state;
  }
}
