import { GET_COSTS_CATEGORY_LIST, RESET_COSTS_CATEGORY_LIST, GET_COST_TYPES_GRID } from '../actions/types';

const initialState = {
  list: []
};
export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_COSTS_CATEGORY_LIST: {
      return { ...state, ...{ list: action.costsCategoryData } };
    }
    case RESET_COSTS_CATEGORY_LIST: {
      return { ...state, ...{ list: [] } };
    }
    default:
      return state;
  }
}

export const costTypesForGrid = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_COST_TYPES_GRID: {
      return { ...state, ...{ list: action.data } };
    }
    default:
      return state;
  }
};
