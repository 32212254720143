// import parseISO from 'date-fns/parseISO';
import { GET_SOLD_STATISTIC_DASHBOARD, UI_START_LOADING, UI_STOP_LOADING } from '../actions/types';

const initialState = () => {
  return {
    dashboard: [],
    isLoading: false
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_SOLD_STATISTIC_DASHBOARD: {
      return { ...state, ...{ dashboard: action.data } };
    }
    case UI_START_LOADING: {
      return { ...state, isLoading: true };
    }
    case UI_STOP_LOADING: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
}
