import { GET_CULTURES } from '../actions/types';

const initialState = {
  list: []
};
// const initialState = [{ id: 1, name: 'Львівська' }, { id: 2, name: 'Київська' }];

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CULTURES: {
      return { ...state, ...{ list: action.culturesData } };
    }
    default:
      return state;
  }
}
