import { ServicesConfig } from './config';
import { DataService } from './dataService';

export default class AuthService {
  static async loginUser(authData) {
    try {
      const userData = {
        email: authData.userName,
        password: authData.password
      };
      const result = await DataService.sendRequest(ServicesConfig.authEndpoint, 'POST', userData);
      return result.error ? result.error : result.data;
    } catch (err) {
      return err;
    }
  }

  static async emailConfirmation(id, token) {
    try {
      const data = {
        user: id,
        token
      };
      const result = await DataService.sendRequest(ServicesConfig.emailConfirmation, 'POST', data, true);
      if (result.error) {
        throw new Error(`${result.error}`);
      } else {
        return result.data;
      }
    } catch (err) {
      throw new Error(err);
    }
  }
}
