import { GET_CLIENT_BANK_REQUISITES, RESET_CLIENT_BANK_REQUISITES } from '../actions/types';

const initialState = () => {
  return {
    list: []
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_CLIENT_BANK_REQUISITES: {
      return { ...state, ...{ list: action.data } };
    }
    case RESET_CLIENT_BANK_REQUISITES: {
      return { ...initialState() };
    }
    default:
      return state;
  }
}
