import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
// import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
// import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const drawerWidth = 240;

const useStyles = theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  grow: {
    flexGrow: 1
  }
});

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClickOutside() {
    this.props.handleVisibilityChange(false);
  }

  getSubString(str) {
    const data = { text: '', link: null };
    const strToArr = str.split(' ');
    const url = new URL(strToArr.pop());
    data.link = url.pathname;
    data.text = strToArr.join(' ');
    return data;
  }

  handleItemClick(idx) {
    this.props.notifications.invoke(idx);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Toolbar>
          <div className={classes.grow} />
          <IconButton
            aria-label="notifications"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => this.props.handleVisibilityChange(!this.props.visibility)}
            color="inherit"
          >
            <Badge
              badgeContent={this.props.notifications?.list?.filter(item => !item.isDeleted).length}
              color="secondary"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <div className={`${this.props.visibility ? 'notificationPanelActive' : ''} notificationPanel`}>
            {this.props?.notifications?.list.map(item => (
              <React.Fragment key={item.id}>
                <Box className={`notificationItem  ${item.isDeleted ? 'readed' : 'unreaded'}`}>
                  {this.getSubString(item.content).text}
                  <Box display="flex" justifyContent="flex-end" mt={1}>
                    <NavLink
                      to={`${this.getSubString(item.content).link}`}
                      onClick={() => this.handleItemClick(item.id)}
                    >
                      <Box display="flex" alignItems="center" fontSize={16} mt={1}>
                        Перейти <ArrowForwardIcon />
                      </Box>
                    </NavLink>
                  </Box>
                </Box>
              </React.Fragment>
            ))}
          </div>
        </Toolbar>
      </>
    );
  }
}

const mapStateToProps = state => ({ notifications: state.notifications });

export default connect(mapStateToProps)(withStyles(useStyles)(enhanceWithClickOutside(Dropdown)));
