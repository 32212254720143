import { GET_MANAGERS_CLIENTS, RESET_MANAGER_CLIENTS } from '../actions/types';

const initialState = () => {
  return {
    list: []
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_MANAGERS_CLIENTS: {
      return { ...state, ...{ list: action.managersClientsData } };
    }
    case RESET_MANAGER_CLIENTS: {
      return { ...initialState() };
    }
    default:
      return state;
  }
}
