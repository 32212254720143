import { GET_NOTIFICATION_LIST, ADD_NOTIFICATION, DELETE_NOTIFICATION, ADD_INVOKE_FUNCTION } from '../actions/types';

const initialState = () => {
  return {
    list: [],
    data: [],
    invoke: () => {}
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_NOTIFICATION_LIST: {
      return { ...state, ...{ list: action.notificationList } };
    }
    case ADD_NOTIFICATION: {
      return { ...state, ...{ list: [action.item, ...state.list] } };
    }
    case DELETE_NOTIFICATION: {
      return { ...state, ...{ list: state.list.filter(notification => notification.id !== action.id) } };
    }
    case ADD_INVOKE_FUNCTION: {
      return { ...state, ...{ invoke: action.func } };
    }
    default:
      return state;
  }
}
