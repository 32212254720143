import { GET_AREAS, GET_AREAS_BY_REGION_ID } from '../actions/types';

const initialState = {
  list: []
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case GET_AREAS: {
      return { ...state, ...{ list: action.areasData } };
    }
    case GET_AREAS_BY_REGION_ID: {
      return { ...state, ...{ list: action.areasData } };
    }
    default:
      return state;
  }
}
