import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import * as serviceWorker from './serviceWorker';

import { ConnectedRouter } from 'connected-react-router';
import jwt_decode from 'jwt-decode';
import ReduxToastr from 'react-redux-toastr';
import configureStore, { history } from './store/configureStore';
import App from './App';
import setAuthToken from './utils/setAuthToken';
import AuthActions from './store/actions/auth';
import createRootReducer from './store/reducers';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import Root from './Root';

const store = configureStore(undefined, createRootReducer);

// window.addEventListener("offline", function(e) {alert("offline");})

// window.addEventListener("online", function(e) {alert("online");})

// Check for token
if (localStorage.jwtToken) {
  if (localStorage.jwtToken === 'undefined' || localStorage.jwtToken === null) {
    // Remove token from localStorage
    localStorage.removeItem('jwtToken');
  } else {
    // Set auth token header auth
    setAuthToken(localStorage.jwtToken);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);
    // Set user and isAuthenticated
    store.dispatch(AuthActions.setCurrentUser(decoded));
    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user
      store.dispatch(AuthActions.logoutUser());
      // Rdirect to login
      window.location.href = '/login';
    }
  }
}

Sentry.init({
  dsn: 'https://c6590a10ec84436f8eab91e449e5a0d7@o1074958.ingest.sentry.io/6076921',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  maxBreadcrumbs: 3,
  debug: true,
  attachStacktrace: false
});

ReactDOM.render(
  <Root store={store}>
    <ConnectedRouter history={history}>
      <App />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-left"
        getState={state => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </ConnectedRouter>
  </Root>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
