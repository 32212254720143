import { ServicesConfig } from './config';

export class DataService {
  static authToken = null;

  static prepareOptions(method, body, file) {
    let requestOptions = {
      method: method || 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${DataService.authToken}`
        // 'Access-Control-Allow-Credentials': true
      }
      // credentials: 'include'
    };
    if (file) {
      requestOptions = {
        method: method || 'GET',
        headers: { Authorization: `Bearer ${DataService.authToken}` }
      };
    }
    if (body && !file) {
      requestOptions.body = JSON.stringify(body);
    } else {
      requestOptions.body = body;
    }
    return requestOptions;
  }

  /**
   *
   * @param {string} action - endpoint to trigger
   * @param {string} method - HTTP request method
   * @param {any} body - object to send
   * @param {boolean} noReturn - say if endpoint return nothing
   * @param {boolean} file - say if data has a file
   */
  static async sendRequest(action, method, body, noReturn, file) {
    //console.log("action", action, "method", method, 'body', body, 'noReturn', noReturn, 'file', file);
    let data = null;
    let error = null;
    try {
      const requestOptions = DataService.prepareOptions(method, body, file);
      const response = await fetch(`${ServicesConfig.baseURL}${action}`, requestOptions);
      if (noReturn) {
        if (!response.ok) {
          const text = await response.text();
          error = text ? text : response.statusText;
        }
      } else {
        const json = await response.json();
        data = json === null ? [] : json;
      }
    } catch (err) {
      error = err;
    }

    return { data, error };
  }
}
