// import parseISO from 'date-fns/parseISO';
import { GET_ACCOUNTS, GET_ACCOUNT_DASHBOARD, UI_START_LOADING, UI_STOP_LOADING } from '../actions/types';

const initialState = () => {
  return {
    list: [],
    dashboard: {
      data: [],
      totalAmount: 0,
      isLoading: false
    }
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_ACCOUNTS: {
      return { ...state, ...{ list: action.data } };
    }
    case GET_ACCOUNT_DASHBOARD: {
      return { ...state, ...{ dashboard: action.data } };
    }
    case UI_START_LOADING: {
      return { ...state, isLoading: true };
    }
    case UI_STOP_LOADING: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
}
