import { GET_TASKS_TYPES_LIST, RESET_TASKS, GET_TASK_TYPES_GRID } from '../actions/types';

const initialState = () => {
  return {
    list: []
  };
};

export default function(state = initialState(), action = {}) {
  switch (action.type) {
    case GET_TASKS_TYPES_LIST: {
      return { ...state, ...{ list: action.tasksTypesData } };
    }
    case RESET_TASKS: {
      return { ...initialState() };
    }
    default:
      return state;
  }
}

export const tastTypesForGrid = (state = initialState(), action = {}) => {
  switch (action.type) {
    case GET_TASK_TYPES_GRID: {
      return { ...state, ...{ list: action.data } };
    }
    default:
      return state;
  }
};
